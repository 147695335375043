@import 'fonts.css';
@import 'statusbar.css';

@tailwind base;
@tailwind components;

html {
    @apply h-full;
}
body {
    @apply text-blue-500 flex flex-col h-full text-base;
    font-family: "Open Sans", sans-serif;
}

a {
    @apply text-blue-400;
}
a:hover {
    @apply text-black;
}

.dotted {
    border: 1px dotted #ADADAD;
}

header .header {
    background-image: url("/images/header_bg.png");
    background-repeat: repeat-x;
}

header .toplogo img {
    max-width: inherit;
}

header .subheader {
    @apply text-white self-center text-sm bg-blue-200 px-4;
}

header input {
    @apply text-black outline-none w-full;
    background-color: #D2D2D2;
    border-radius: 7px;
    line-height: 28px;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
}

header button {
    @apply ml-5 px-3 font-bold;
    background: linear-gradient(#BED9BE, #2F8531);
    border: 1px solid black;
    line-height: 28px;
}
header button:hover {
    @apply text-yellow-500;
}

nav .nav {
    background-image: url("/images/menubar_bg.png");
    background-repeat: repeat-x;
}

nav .nav ul {
    @apply flex w-full justify-between py-1 flex-col;
}

nav .nav ul li {
    @apply flex w-full mx-1 items-center justify-center text-white font-bold text-sm;
    background-image: url("/images/menubutton_default.png");
    background-repeat: repeat-x;
    border-top: 1px solid #44739C;
    border-left: 1px solid #44739C;
    border-right: 1px solid #44739C;
    height: 47px;
}
nav .nav ul li.menu {
    @apply flex justify-start px-2;
    border-top: medium none;
    border-left: medium none;
    border-right: medium none;
}
nav .nav ul li.menu-item {
    @apply hidden;
}
nav .nav ul li:first-child {
    @apply ml-0;
}
nav .nav ul li:last-child {
    @apply mr-0;
}
nav .nav ul li.active {
    @apply text-yellow-500;
    background-image: url("/images/menubutton_active.png");
}
nav .nav ul li a {
    @apply text-white;
}
nav .nav ul li.active a {
    @apply text-yellow-500;
}

.show-menu {
    display: flex !important;
}

.sitecontainer {
    border-left: 1px dotted #ADADAD;
    border-right: 1px dotted #ADADAD;
    @apply p-2;
}

.breadcrumb {
    @apply w-full px-2;
    background-color: #e3e3e3;
    background-image: url("/images/boxheader_bg.png");
    background-repeat: repeat-x;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
}
.breadcrumb ul {
    @apply flex font-bold text-sm h-full flex-col;
}
.breadcrumb li.item:before {
    content: '\00BB';
}
.breadcrumb li.item {
    @apply ml-2 font-normal;
}

.product-grid {
    @apply grid grid-cols-2 gap-2 p-2 mt-3;
    border: 1px dotted #ADADAD;
}

.subhead {
    @apply font-bold px-2;
    line-height: 28px;
    background-image: url("/images/boxheader_bg.png");
}

.hl_2 {
    @apply font-semibold px-2;
    line-height: 24px;
    background-image: url("/images/boxheader_bg.png");
}

.firma-grid {
    @apply grid grid-cols-2 gap-2 p-2 mt-3 text-xs;
    border: 1px dotted #ADADAD;
}
.firma-grid-item a {
    @apply block text-sm px-1 font-semibold truncate;
    line-height: 28px;
    background-image: url("/images/boxheader_bg.png");
}

.category-grid {
    @apply grid grid-cols-2 gap-2 p-2 mt-3 text-xs;
    border: 1px dotted #ADADAD;
}
.category-grid-item a {
    @apply truncate;
}

.abc {
    @apply flex;
    grid-template-columns: repeat(13, minmax(0, 1fr));
}
.abc li { @apply pr-1; }
.abc li:first-child:before {
    content: none;
}

.cloud {
    @apply gap-2 p-2 mt-3;
    border: 1px dotted #ADADAD;
}

.social {
    @apply flex items-center text-xs px-2;
    line-height: 28px;
    background-color: #E5E5E5;
}
.social-links {
    @apply flex;
}
.social-links a {
    @apply ml-2;
}

.footer {
    @apply text-gray-900 text-xs flex items-center p-2;
    background-image: url("/images/footer_bg.png");
    background-repeat: repeat-x;
    min-height: 135px;
}

.page-wrap {
    @apply flex mt-4 flex-col;
}

.page-wrap .content {
    @apply mb-4;
}

.has-errors {
    @apply italic text-red-800;
}

.categories-list {
    gap: 0.5rem 1rem;
}

.maps {
    @apply flex items-center justify-center dotted;
    height: 300px;
}

#map {
    @apply flex items-center justify-center flex-col w-full h-full;
    min-height: 300px;
}

.hideGoogleMaps {
    @apply hidden text-sm;
}
.hideGoogleMaps a {
    @apply text-gray-600;
}

.showOnMap {
    @apply text-xs text-green-300 cursor-pointer;
}

.result-footer {
    @apply bg-gray-400 p-2 text-sm grid;
    grid-template-columns: auto 1fr;
}
.result-footer img {
    @apply mr-2;
    width: 16px;
    height: 16px;
}
.result-footer a {
    @apply mr-4;
}

.result-logo {
    @apply pr-1
}

.result-logo img {
    max-height: 75px;
}

.pagination-wrapper {
    @apply flex my-4 bg-gray-400 px-2 py-1 text-xs;
}
.pagination {
    @apply flex;
}
.pagination li {
    @apply px-2;
}
.pagination li.active {
    @apply font-bold text-gray-900;
}

hr {
    @apply my-2;
}

@media  (min-width: 768px) {
    .page-wrap {
        flex-direction: row-reverse;
    }
    header input {
        width: 368px;
    }
    .content {
        @apply w-3/4 ml-2 mb-0;
    }
    .sidebar {
        @apply w-1/4 mr-2;
    }
    .product-grid {
        @apply grid-cols-3;
    }
    .firma-grid, .category-grid {
        @apply grid-cols-4;
    }
    .footer {
        @apply p-0;
    }
    .abc li:before {
        @apply text-blue-300 font-normal;
        content: '\0002f ';
    }

    header .header {
        height: 203px;
    }
    header .subheader {
        @apply bg-transparent px-0;
    }
    nav .nav ul {
        @apply flex-row;
        height: 55px;
    }
    nav .nav ul li {
        height: auto;
    }
    nav .nav ul li.menu {
        @apply hidden;
    }
    nav .nav ul li.menu-item {
        @apply flex;
    }
    .breadcrumb {
        height: 28px;
    }
    .breadcrumb ul {
        @apply items-center flex-row;
    }
    .result-footer {
        @apply flex;
    }
}

.notify {
    @apply px-2 py-1 font-bold text-white mb-4;
}
.notify_success {
    @apply bg-green-300;
}
.notify_failure {
    @apply bg-red-600;
}

.company-whatsapp {
    max-width: 125px;
}

@tailwind utilities;
